import React from "react";
import "./Media.css"

import VideoCard from './VideoCard/VideoCard'
import PhotoCard from './PhotoCard/PhotoCard'


const VIDEO_DATA = [
    {link: "https://www.youtube.com/embed/t_CtLZM1f0U?si=_Q33N6S4CmpRLwAp", caption: "World's Greatest Tribute Bands Performance", description: "Recorded live at the Roxy Theater in Hollywood, CA on August 5, 2013 for a live television broadcast on the AXSTV show World's Greatest Tribute Bands hosted by Katie Daryl."},
    {link: "https://www.youtube.com/embed/ZWEIDbCghRc?si=W9kxPCniDAS5KqEY", caption: "2024 at Lava Cantina", description: "2024 Live at the Lava Cantina, The Colony, TX."},
    {link: "https://www.youtube.com/embed/KX3dHnmqnfc?si=lewuxM51etWwGII4", caption: "Paradise City (Live)", description: "Edmonton Eskimos home opener halftime show."},
    {link: "https://www.youtube.com/embed/t9gADOTvIYA?si=kv7zMDKijfLuwhgI", caption: "Guns 4 Roses en Hermosillo", description: "Concierto número siete de Stereo 100."},
]

const PROMO_DATA = [
    {image: require("./../../assets/photos/Guns4RosesPromo123.jpg"), caption: "Promo Photo 1", copyright: ""},
    {image: require("./../../assets/photos/Guns4RosesPromo223.jpg"), caption: "Promo Photo 2", copyright: ""},
]

const PHOTO_DATA = [
    {image: require("./../../assets/photos/G4R-14-sm.jpg"), caption: "G4R 14", copyright: "2015 Kenny Lee Photography"},
    {image: require("./../../assets/photos/G4R-23-sm.jpg"), caption: "G4R 23", copyright: "2015 Kenny Lee Photography"},
    {image: require("./../../assets/photos/G4R-63-sm.jpg"), caption: "G4R 63", copyright: "2015 Kenny Lee Photography"},
    {image: require("./../../assets/photos/G4R-67-sm.jpg"), caption: "G4R 67", copyright: "2015 Kenny Lee Photography"},
    {image: require("./../../assets/photos/G4R-73-sm.jpg"), caption: "G4R 73", copyright: "2015 Kenny Lee Photography"},
    {image: require("./../../assets/photos/G4R-77-sm.jpg"), caption: "G4R 77", copyright: "2015 Kenny Lee Photography"},
    {image: require('./../../assets/photos/g4r-hob-1sm.jpg'), caption: "G4R HOB 1", copyright: "2011 Chris Ossenfort Photography"},
    {image: require('./../../assets/photos/g4r-hob-2sm.jpg'), caption: "G4R HOB 2", copyright: "2011 Chris Ossenfort Photography"},
    {image: require('./../../assets/photos/g4r-hob-3sm.jpg'), caption: "G4R HOB 3", copyright: "2011 Chris Ossenfort Photography"},
    {image: require('./../../assets/photos/g4r-hob-4sm.jpg'), caption: "G4R HOB 4", copyright: "2011 Chris Ossenfort Photography"},
    {image: require('./../../assets/photos/g4r-hob-6sm.jpg'), caption: "G4R HOB 6", copyright: "2011 Chris Ossenfort Photography"},
    {image: require('./../../assets/photos/g4r-hob-7sm.jpg'), caption: "G4R HOB 7", copyright: "2011 Chris Ossenfort Photography"},
    {image: require("./../../assets/photos/h-mex-axl1-sm.jpg"), caption: "Mex Axl 1", copyright: "2009 Alexander Potiomkin Photography"},
    {image: require("./../../assets/photos/h-mex-axl3-sm.jpg"), caption: "Mex Axl 3", copyright: "2009 Alexander Potiomkin Photography"},
    {image: require("./../../assets/photos/h-mex-axl4-sm.jpg"), caption: "Mex Axl 4", copyright: "2009 Alexander Potiomkin Photography"},
    {image: require("./../../assets/photos/h-mex-slash1-sm.jpg"), caption: "Mex Slash 1", copyright: "2009 Alexander Potiomkin Photography"},
    {image: require("./../../assets/photos/h-mex-slash2-sm.jpg"), caption: "Mex Slash 2", copyright: "2009 Alexander Potiomkin Photography"},
    {image: require("./../../assets/photos/h-mex-slash3-sm.jpg"), caption: "Mex Slash 3", copyright: "2009 Alexander Potiomkin Photography"},
]


function Videos() {
    return(
        <div className="media-container">
            <div className="section-container-outer">
                <h2 className='logo-color1'>Videos</h2>
                <div className="section-container-inner">
                    {
                        VIDEO_DATA.map((data, index) => (
                            <VideoCard key={index} link={data.link} caption={data.caption} description={data.description}/>
                        ))
                    }
                </div>
            </div>

            <div className="section-container-outer">
                <h2 className='logo-color1'>Promo Photos</h2>
                <div className="description-container">
                    These photos may be used for press and promotional purposes relating to Guns 4 Roses appearances. They may not be used for other purposes without permission. Photo Credit: Chris Ossenfort Photography.
                </div>
                <div className="section-container-inner">
                    {
                        PROMO_DATA.map((data, index) => (
                            <PhotoCard key={index} image={data.image} caption={data.caption} copyright={data.copyright}/>
                        ))
                    }
                </div>
            </div>

            <div className="section-container-outer">
                <h2 className='logo-color1'>Live Photos</h2>
                <div className="description-container">
                    All photos below are copyright protected. Please do not use without contacting us for permission.
                </div>
                <div className="section-container-inner">
                    {
                        PHOTO_DATA.map((data, index) => (
                            <PhotoCard key={index} image={data.image} caption={data.caption} copyright={data.copyright}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Videos;